import { AppShell, Box } from "@mantine/core";
import { useNavigation } from "@remix-run/react";
import Header, { NavRoute } from "~/components/Header/Header";
import { centeredWithPadding } from "~/styles/centeredWithPadding";

type AppLayoutProps = {
  children: React.ReactNode;
  navRoutes?: NavRoute[];
  withPadding?: boolean;
};

export const headerHeight = 60;

export default function AppLayout({
  children,
  navRoutes,
  withPadding = false,
}: AppLayoutProps) {
  const navigation = useNavigation();
  const isNavigating = navigation.state !== "idle";

  return (
    <AppShell className="w-full relative" header={{ height: headerHeight }}>
      <Header navRoutes={navRoutes ?? []} />
      <AppShell.Main className="bg-neutral-50 w-full flex flex-col">
        {isNavigating && (
          <div className="w-full">
            <div className="h-1 bg-primary-600 animate-pulse" />
          </div>
        )}
        <Box pos="relative" style={{ minHeight: "100%", width: "100%" }}>
          {withPadding ? (
            <div className={centeredWithPadding}>{children}</div>
          ) : (
            children
          )}
        </Box>
      </AppShell.Main>
    </AppShell>
  );
}
