import { Anchor, AppShell, Box, Group, Image } from "@mantine/core";
import { useMatches } from "@remix-run/react";
import AuthButton from "../Auth/AuthButton";

const HeaderAnchor = ({
  href,
  label,
  matchingPaths,
}: {
  href: string;
  label: string;
  matchingPaths: string[];
}) => (
  <Anchor
    href={href}
    className="flex items-center h-full px-4 text-white font-medium text-sm"
    underline={matchingPaths.includes(href) ? "always" : "hover"}
  >
    {label}
  </Anchor>
);

export type NavRoute = {
  href: string;
  label: string;
};

type HeaderProps = {
  navRoutes: NavRoute[];
};

export default function Header({ navRoutes }: HeaderProps) {
  const matches = useMatches();
  const matchingPaths = matches.map((match) => match.pathname);

  return (
    <Box>
      <AppShell.Header className="border-none h-[60px] px-4 bg-secondary-700 text-white">
        <Group justify="space-between" h="100%">
          <Group>
            <Group>
              <a href="/">
                <Image
                  alt="Podsie logo"
                  src="/logo512.png"
                  className="max-w-12"
                />
              </a>
            </Group>
            <Group h="100%" gap={0}>
              {navRoutes.map((route) => (
                <HeaderAnchor
                  key={route.href}
                  href={route.href}
                  label={route.label}
                  matchingPaths={matchingPaths}
                />
              ))}
            </Group>
          </Group>

          <Group>
            <Group>
              <AuthButton />
            </Group>
          </Group>
        </Group>
      </AppShell.Header>
    </Box>
  );
}
